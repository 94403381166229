import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 6; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/22-dec-23-2/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function ChandigarhTripClassIVV2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Chandigarh Trip for the students of classes IV and V
        </h1>

        <p>
          Chandigarh is unique for being the only city that is the capital of
          two states and being the first planned city of independent India.
          <br />
          <br />
          The learners absorbed culture and knowledge at the Government Museum
          of Art. The beautiful paintings, sculptures, and artifacts were a
          wonder to behold.
          <br />
          <br />
          Rock Garden fascinated the students with its interesting artwork of
          recycled household, electrical, and ceramic waste. They understood the
          need to segregate, recycle, and upcycle the waste.
          <br />
          <br />
          Pinjore Garden with its vibrant greenery and refreshing fountains was
          a delight for the children.
          <br />
          <br />
          Mohendra Chaudhry Zoological Park or as it is popularly known,
          Chhatbir Zoo, captivated the children with its wide variety of birds,
          mammals, and reptiles.
          <br />
          <br />
          The trip was a thrilling experience for all the children and they
          returned with pleasant memories.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
